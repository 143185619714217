<template>
  <div class="footer">
    <div class="footerAll">
      <div class="footerAllBook">
        <span>
          官方网站:
          <a href="https://www.zhenfengit.com">https://www.zhenfengit.com</a>
          Copyright © 2020-2021 All Rights Reserved. 备案号:
          <a href="http://beian.miit.gov.cn" target="_blank">粤ICP备2024213088号</a>
        </span>  
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "footers",
    data() {
      return {
        // msg: 'Welcome to Your Vue.js App'
      };
    },
  };
</script>

<style>
  .footer {
    width: 100%;
    height: 40px;
  }
  .footerAll {
    width: 100%;
    height: 40px;
    color: #000;
    background: #ffffff;
    border-radius:  40px 40px 0px 0px;
    margin: auto !important;
  }
  .footerAllBook {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
  .footerAllBook span a{
    color: #000;
  }
  .footerAllBook span a:hover{
    color: #000;
    /* text-decoration: none; */
  }
</style>
