<template>
  <div id="Home">
    <div  class="homeMax d-none d-md-block">
      <div class="homeMaxBlank"/>
      <div class="content">
        <div class="homeMaxSide"/>
        <div class="homeMaxClassify">
          <div class="homeMaxClassifyNavbar">分类</div>
          <div class="homeMaxClassifyContent" @click="categoryClick({ name: '全部', id: '' })" >全部</div>
          <div class="homeMaxClassifyContent" v-for="item in categoryList" v-bind:key="item.id" @click="categoryClick(item)" >{{ item.name }}</div>
          <div class="homeMaxClassifyFooter"/>
          <div class="homeMaxClassifyNavbar">友情链接</div>
          <div class="homeMaxClassifyContent" v-for="item in shareConnection" v-bind:key="item.name" @click="skip(item)" >{{ item.name }}</div>
          <div class="homeMaxClassifyFooter"/>
        </div>
        <div class="homeMaxContent">
          <div class="homeMaxContentNavbar"/>
          <div class="homeMaxContentContent" v-for="item in blogData" v-bind:key="item.blogId" @click="goToInfo(item.id)">
            <Abstract0 :datas="item"/>
          </div>
          <div class="homeMaxContentFooter">
            <el-pagination 
              background layout="prev, pager, next" :total="rows" :page-size="perPage"
              :current-page="currentPage" @current-change="handleCurrentChange" style="margin: 8px;"/>
          </div>
        </div>
      </div>
    </div>
    
    <div  class="navbarXiao d-block d-md-none">
      <div class="homeMinContent">
        <div class="homeMaxContentNavbar"/>
        <div class="homeMaxContentContent" v-for="item in blogData" v-bind:key="item.blogId" @click="goToInfo(item.id)">
          <Abstract0 :datas="item"/>
        </div>
        <div class="homeMaxContentFooter">
          <el-pagination 
            background layout="prev, pager, next" :total="rows" :page-size="perPage" 
            :current-page="currentPage" @current-change="handleCurrentChange" style="margin: 8px;"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import utils from "@/config/config.js";
  import Abstract0 from "@/pages/abstractTemplate/abstract0";
  export default {
    name: "Home",
    components: {
      Abstract0,
    },
    props: {},
    data() {
      return {
        rows: 1000,
        perPage: 10,
        currentPage: 1,
        categoryList: [],
        categoryName: "全部",
        categoryId: "",
        blogData: [],
        blogParams: {},
        shareConnection: [
          {'name':'spring', 'url':'https://spring.io/projects'},
          {'name':'ElementUi', 'url':'https://element.eleme.cn/#/zh-CN'},
          {'name':'GitHub', 'url':'https://github.com/'},
          {'name':'Vue', 'url':'https://cn.vuejs.org/'},
          {'name':'MyBatis', 'url':'https://mybatis.org/'},
          {'name':'BootstrapUi', 'url':'https://bootstrap-vue.org/'},
        ],
      };
    },
    mounted() {
      this.selectBlogList();
      this.blogCategorySelect();
    },
    methods: {
      skip(data) {
        window.open(data.url)
      },
      blogCategorySelect() {
        let _this = this;
        let parameter = {
          pageNo: 1,
          pageSize: 20,
        };
        $.ajax({
          type: "GET",
          url: utils.url + "/api/blogCategory/blogCategorySelect",
          data: parameter,
          success: function(data, state, ret) {
            if (ret.status === 200) {
              if (data.code === 200) {
                _this.categoryList = data.data.records;
              } else {
                _this.$message.error(data.msg);
              }
            }
          },
          error: function(ret) {
            if (ret.status === 403 || ret.status === 401) {
              // _this.routerPath('/login')
            }
          },
          beforeSend: utils.beforeSend,
          xhrFields: utils.xhrFields,
        });
      },
      selectBlogList() {
        let _this = this;
        let parameter = {
          pageNo: this.currentPage,
          pageSize: this.perPage,
          blogCategoryId: this.blogParams.blogCategoryId
        };
        $.ajax({
          type: "GET",
          url: utils.url + "/api/blog/selectBlogList",
          data: parameter,
          success: function(data, state, ret) {
            if (ret.status === 200) {
              if (data.code === 200) {
                _this.blogData = data.data.records;
                _this.rows = data.data.total;
              } else {
                _this.$message.error(data.msg);
              }
            }
          },
          beforeSend: utils.beforeSend,
          xhrFields: utils.xhrFields,
        });
      },
      categoryClick(item) {
        this.categoryName = item.name;
        this.categoryId = item.id;
        this.blogParams.blogCategoryId = this.categoryId;
        this.selectBlogList();
      },
      goToInfo(val) {
         window.open(utils.webUrl + "/#/particulars?blogId=" + val);
        // this.routerPath("/particulars?blogId=" + val);
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.selectBlogList();
      },
    },
  };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only lang= "scss"  lang="less"-->
<style scoped>
  #Home {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .content {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
  .homeMaxSide {
    width: 10vw;
    height: calc(100vh - 140px);
    margin: 0;
    padding: 0;
    float: left;
  }
  .homeMaxClassify {
    float: left;
    width: calc((80vw / 6) * 1);
  }
  .homeMaxContent {
    float: left;
    width: calc((80vw / 6) * 5);
  }
  .homeMax {
    width: 100%;
    height: 100%;
  }
  .homeMaxBlank {
    width: 100%;
    height: 40px;
  }
  .homeMaxClassifyNavbar {
    float: left;
    width: 80%;
    background: #ffffff;
    height: 40px;
    border-radius:  30px 30px 0px 0px;
    line-height: 40px;
    border-bottom: 1px solid #f5f5f5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .homeMaxClassifyContent {
    float: left;
    width: 80%;
    background: #ffffff;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #f5f5f5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .homeMaxClassifyContent:hover {
    background: #ffffff9a;
  }
  .homeMaxClassifyFooter {
    float: left;
    width: 80%;
    background: #ffffff;
    height: 30px;
    border-radius:  0px 0px 30px 30px;
    margin: 0px 0px 30px 0px ;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .homeMaxContentNavbar {
    float: right;
    width: 100%;
    background: #ffffff;
    height: 30px;
    border-radius:  30px 30px 0px 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-bottom: 1px solid #f5f5f5;
  }
  .homeMaxContentContent {
    float: right;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-bottom: 1px solid #f5f5f5;
  }
  .homeMaxContentContent:hover {
    background: #ffffff9a;
  }
  .homeMaxContentFooter {
    float: right;
    width: 100%;
    height: 50px;
    background: #ffffff;
    border-radius:  0px 0px 30px 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0px 0px 30px 0px ;
  }
  .homeMinContent {
    float: left;
    width: 100%;
    padding: 5vw;
  }
</style>
