export default {
  // url: 'http://127.0.0.1:9000',
  // webUrl: 'http://127.0.0.1:8080',
  url: 'https://www.zhenfengit.com',
  webUrl: 'https://www.zhenfengit.com',
  routerPath: function (pa) {
      if (this.$route.path !== pa) {
        this.$router.push({ path: pa })
      }
  },
  beforeSend: function (xhr) {
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
    xhr.withCredentials = true
  },
  xhrFields: {
    withCredentials: true
  },
  jsonp: 'callback',
  error: function (data) {
    this.$message.error(data.msg)
  },
  contentType: 'application/json',
  paths: [
    "/",
    "/particulars",
    "/login",
    "/publish",
    "/myBlog",
    "/blogCategory",
    "/info",
  ],
}
