<template>
  <div id="app">
    <Navbar v-show="isShiw" />
    <router-view />
    <Footer v-show="isShiw"/>
  </div>
</template>

<script>
  import Navbar from "./navbar/navbar.vue";
  import Footer from "./footer/footer.vue";
  import utils from '@/config/config.js'
  export default {
    name: "App",
    components: {
      Navbar,
      Footer,
    },
    data() {
      return {
        isShiw: true,
        paths: utils.paths,
      };
    },
    mounted() {
    },
    watch: {
      $route(to) {
        if(this.paths.includes(to.path)){
          this.isShiw = true
        }else{
          this.isShiw = false
        }
      },
      immediate: true,
    },
  };
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #000;
    background: #f5f5f5;
  }
  * {
    margin: 0;
    padding: 0;
    border: 0;
  }
</style>
