<template>
  <div id="Abstract0">
    <h5 class="title">{{datas.title}}</h5>
    <div class="author">
      <p>作者：{{datas.authorName}}</p>
    </div>
  </div>
</template>

<script>
import utils from '@/config/config.js'
export default {
  name: 'Abstract0',
  components: {
  },
  props: {
    datas: {}
  },
  data () {
    return {
      
    }
  },
  mounted () {
  },
  methods: {
   
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only lang= "scss"  lang="less"-->
<style scoped>
  #Abstract0 {
    width: 100%;
    float: left;
  }
  .title {
    width: 90%;
    line-height: 60px;
    margin: auto;
  }
  .author {
    width: 97%;
    height: 20px;
    line-height: 20px;
    margin: 0px 0px 5px 0px;
    text-align: right;
  }
</style>
