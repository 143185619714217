<template>
  <div id="navbar">
    <div class="side"></div>
    <div  class="navbarMax d-none d-md-block">
        <img class="navbarMaxlogo" src="@/assets/images/logo1.png" @click="routerPathHome()">

        <div class="navbarMaxA">
          <a class="navbarMaxAs a1" href="#/" @click="toHref('a1')">首页</a>
          <a class="navbarMaxAs a2" href="#/" @click="toHref('a2')">图片</a>
          <el-dropdown class="navbarMaxAdminA" v-if="isLogin">
            <span class="el-dropdown-link">
              <a class="navbarMaxAs a3" v-if="isLogin">管理</a>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="routerPath('/myBlog'); toHref('a3')"><a>文章管理</a></el-dropdown-item>
              <el-dropdown-item @click.native="routerPath('/publish'); toHref('a3')"><a>文章发布</a></el-dropdown-item>
              <el-dropdown-item @click.native="routerPath('/blogCategory'); toHref('a3')" v-if="isAdmin"><a >文章分类管理</a></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <a class="navbarMaxAs a6 navbarMaxlogin" href="#/login" @click="toHref('a6')" v-if="!isLogin">登陆</a>
          <el-dropdown class="navbarMaxInfo" v-if="isLogin">
            <span class="el-dropdown-link">
              <el-avatar icon="el-icon-user-solid" :src="circleUrl"/>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="info()">{{userName}}</el-dropdown-item>
              <el-dropdown-item @click.native="logout()">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          
        </div>
    </div>
    
    <div  class="navbarXiao d-block d-md-none">
      <img class="navbarMaxlogo" src="@/assets/images/logo1.png" @click="routerPath('/')">

      <div class="navbarXiaoA">
        <el-dropdown class="navbarXiaoDropdown">
          <span class="el-dropdown-link">
            <i class="el-icon-s-fold"/>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="routerPath('/')">首页</el-dropdown-item>
            <el-dropdown-item @click.native="routerPath('/')">图片</el-dropdown-item>

            <el-dropdown-item divided @click.native="routerPath('/login')" v-if="!isLogin">登陆</el-dropdown-item>
            <el-dropdown-item divided @click.native="routerPath('/')" v-if="isLogin">{{userName}}</el-dropdown-item>
            <el-dropdown-item @click.native="logout()" v-if="isLogin">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
  import utils from '@/config/config.js'
  export default {
    name: 'Havbar',
    data () {
      return {
        circleUrl: this.$store.state.circleUrl,
        userName: this.$store.state.userName,
        isLogin: this.$store.state.isLogin,
        intervalId: null,
        isAdmin: this.$store.state.isAdmin,
        lastHref: 'a1',
      }
    },
    mounted () {
      this.getUserInfo()
    },
    computed:{
      circleUrlData(){
        return this.$store.state.circleUrl
      },
      isLoginData(){
        return this.$store.state.isLogin
      },
      userNameData(){
        return this.$store.state.userName
      },
      isAdminData(){
        return this.$store.state.isAdmin
      }
    } ,
    watch:{
        circleUrlData(newFlag, oldFlag){
          this.circleUrl = newFlag
        },
        isLoginData(newFlag, oldFlag){
          this.isLogin = newFlag
        },
        userNameData(newFlag, oldFlag){
          this.userName = newFlag
        },
        isAdminData(newFlag, oldFlag){
          this.isAdmin = newFlag
        }
    },
    methods: {
      toHref(href){
        var lastClassName = document.getElementsByClassName('navbarMaxAs');
        if(lastClassName){
          lastClassName.forEach(currentItem => {
            currentItem.style.boxShadow ='';
            
          });
        }
        this.lastHref = href;
        var className = document.getElementsByClassName(href);
        if(className[0]) {
          className[0].style.boxShadow ='inset 0px 4px 4px rgba(0, 0, 0, 0.25)';
        }
      },
      routerPathHome (){
        this.routerPath('/')
        this.toHref('a1')
      },
      routerPath (pa) {
        if (this.$route.path !== pa) {
          this.$router.push({ path: pa })
        }
      },
      getUserInfo () {
        let _this = this
        $.ajax({
          type: 'GET',
          url: utils.url + '/api/user/getUserInfo',
          data: {},
          jsonp: utils.jsonp,
          success: function (data, state, ret) {
            if (ret.status === 200) {
              if (data.code === 200) {
                _this.$store.state.userName = data.data.nickName
                _this.$store.state.circleUrl = data.data.headPortrait
                _this.$store.state.isLogin = true
                _this.$store.state.isAdmin = data.data.isAdmin
                _this.intnUserInfo()
              }
            }
          },
          error: function (ret) {
            if (ret.status === 403 || ret.status === 401) {
              _this.$store.state.userName = '请登录'
              _this.$store.state.circleUrl = ''
              _this.$store.state.isLogin = false
              _this.intnUserInfo()
            }
          },
          beforeSend: utils.beforeSend,
          xhrFields: utils.xhrFields
        })
      },
      intnUserInfo () {
        this.userName = this.$store.state.userName
        this.circleUrl = this.$store.state.circleUrl
        this.isLogin = this.$store.state.isLogin
        this.isAdmin = this.$store.state.isAdmin
      },
      // // 定时刷新数据函数
      // dataRefreh () {
      //   // 计时器正在进行中，退出函数
      //   if (this.intervalId != null) {
      //     return
      //   }
      //   let _this = this
      //   // 计时器为空，操作
      //   this.intervalId = setInterval(() => {
      //     _this.intnUserInfo ()
      //     if (this.isLogin === true){
      //       _this.clear()
      //     }
      //   }, 500)
      // },
      // // 停止定时器
      // clear () {
      //   if (this.intervalId != null) {
      //     //清除计时器
      //     clearInterval(this.intervalId)
      //     //设置为null
      //     this.intervalId = null
      //   }
      // },
      logout () {
        $.ajax({
          type: 'GET',
          url: utils.url + '/api/user/logout',
          data: {},
          jsonp: utils.jsonp,
          beforeSend: utils.dataRefreh,
          xhrFields: utils.xhrFields
        })
        this.$store.state.userName = '请登录'
        this.$store.state.circleUrl = ''
        this.$store.state.isLogin = false
        this.$store.state.isAdmin = false
        this.intnUserInfo()
        // this.dataRefreh()
        this.toHref('a1')
        this.routerPath('/')
      },
      info () {
        this.toHref('a6')
        this.routerPath('/info')
      }
    }
  }
</script>

<style scoped>
  .navbar {
    display: flex;
  }
  .navbarMax{
    width: 100%;
    height: 60px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius:0px 0px 40px 40px;
  }
  .navbarMaxlogo{
    width: 50px;
    height: 50px;
    float: left;
    margin: 4px 10px 0 50px !important;
  }
  .navbarMaxA {
    float:left;
    width: calc(100% - 160px);
  }
  .navbarMaxA a{
    float:left;
    color: #000;
    margin: 8px 0px;
    padding: 10px;
  }
  .navbarMaxA a:hover{
    color: #000;
    text-decoration: none;
  }
  .navbarMaxAdminA {
    float:left;
  }
  .navbarMaxAdminA .el-dropdown-link a {
    font-size: 16.5px;
  }
  .el-dropdown-menu__item a {
    color: #000 !important;
  }
  .el-dropdown-menu__item a:hover {
    color: #000 !important;
    text-decoration: none;
  }
  .el-dropdown-menu__item {
    color: #000 !important;
  }
  .el-dropdown-menu__item:hover {
    color:  #000 !important;
    background: #f5f5f5 !important;
  }
  .navbarMaxlogin{
    float: right !important;
    margin: 10px 0px 10px 0;
  }
  .navbarMaxInfo{
    float: right !important;
    margin: 7px 0 0 0;
  }

  .navbarXiao {
    width: 100%;
    height: 60px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 40px 40px;
  }
  .navbarMaxlogo {
    width: 50px;
    height: 50px;
    float: left;
    margin: 4px 10px 0 30px !important;
  }
  .navbarXiaoDropdown {
    float: right;
    font-size: 40px;
    margin: 0px 0px 0px 0px !important;
  }
  .navbarXiaoA {
    float: right;
    width: calc(100% - 160px);
    margin: 0px 30px 0px 0px;
  }
  .navbarXiaoA a{
    float: right;
    color: #000;
    margin: 8px 0px;
    padding: 10px;
  }
  .navbarXiaoA a:hover{
    color: #000;
    text-decoration: none;
  }
  .side {
    width: 0px;
    height: calc(100vh - 60px);
    float: left;
  }
</style>