import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/home/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/particulars',
    name: 'particulars',
    component: () => import('../pages/particulars/particulars.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../pages/login/login.vue')
  },
  {
    path: '/publish',
    name: 'publish',
    component: () => import('../pages/publish/publish.vue')
  },
  {
    path: '/myBlog',
    name: 'myBlog',
    component: () => import('../pages/myBlog/myBlog.vue')
  },
  {
    path: '/blogCategory',
    name: 'blogCategory',
    component: () => import('../pages/blogCategory/blogCategory.vue')
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('../pages/info/info.vue')
  },
  {
    path: '/*',
    component: () => import('../pages/404')
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
